<template>
  <div class="content">
    <sub-title>카지노/슬롯 베팅내역</sub-title>
    <bet-history-links></bet-history-links>
    <table class="utable">
      <tr>
        <th style="width: 10%">시간</th>
        <th style="width: 10%">번호</th>
        <th style="width: 10%">제목</th>
        <th style="width: 10%">베팅금액</th>
        <th style="width: 10%">당첨금</th>
      </tr>
      <tr  v-for="(item,idx) in resultList" :key="idx">
        <td>{{item.createTime|datef('HH:mm')}}</td>
        <td>{{item.id}}</td>
        <td>
          {{item.title}}
        </td>
        <td>
          {{item.betamount | comma}}
        </td>
        <td>
          {{item.winamount | comma}}
        </td>
      </tr>
    </table>


    <pagination :page-index=" pageNum"
                :total="total"
                :page-size="pageSize"
                @change="pageChange"></pagination>

    <div class="history_btns">
      <img src="../../../assets/images/afront/btn/베팅전체삭제.png" alt=""  v-if="position" @click="deleteAll"/>
<!--      <button class="delall"  @click="deleteAll"-->
<!--              v-if="position">-->
<!--        전체삭제-->
<!--      </button>-->

    </div>

    </div>

</template>


<script>

    import {delQuestion, getQuestionList, saveQuestion} from "@/network/userRequest";
    import Pagination from "@/components/pagenation/Pagination";
     import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";
    import {postionMixin} from "@/common/mixin";
    import sportsConst from "@/common/sportsConst";
    import SubTitle from "@/views/afront/zero/SubTitle.vue";
    import leisureConst from "@/common/leisureConst";
    import {deleteAllHonorBet, getHonorBetList} from "@/network/casinoHonorRequest";
    import BetHistoryLinks from "@/views/afront/sports/match/BetHistoryLinks.vue";

    export default {
      name: "CasinoSlotBetInfo",
      mixins: [postionMixin],
      components: {
        BetHistoryLinks,
        Pagination, SubTitle,

      },
      data() {
        return {
          position: "C베팅내역",
          leisureConst,
          sportsConst,
          pageNum: 1,
          pageSize: 20,
          total: 1,
          resultList: []
        }
      },
      methods: {
        deleteAll(){
          this.$swal({
            title: '베팅내역 전체를 삭제하시겠습니까?',
            type: 'question',
            confirmButtonText: ' 예 ',
            cancelButtonText: '아니오'
          }).then((res) => {
            if (res.value) {
              deleteAllHonorBet().then(res=>{
                this.getHonorBetList()
              })
            }
          });

        },
        getHonorBetList() {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          getHonorBetList(this.pageNum, this.pageSize, this.orderBy).then(res => {
            if (res.data.success) {
              this.total = res.data.total
              this.resultList = res.data.data
            }
            this.$store.commit(RECEIVE_HIDE_LOADING)
          })
        },
        pageChange(page) {
          this.pageNum = page
          this.getHonorBetList()
        }
      },
      created() {
        this.getHonorBetList();
        this.$store.state.currentBetHistoryIndex = 3
      },
    }
</script>

<style scoped>
    @import url("../../../assets/css/front/afrontcasinoslot.css");

</style>